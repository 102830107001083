.entitiesMenu {
    min-width: 200px;
    max-width: 250px;
}

.entities-title {
    position: sticky;
    top: 0;
    z-index: 100;
    font-size: 15px;
    background-color: white;
}

.entitiesMenu > .pf-c-nav__list > .pf-c-nav__item > .pf-c-nav__link {
    position: sticky;
    top: 18px;
    background-color: white;
    z-index: 50;
}

.entitiesMenu .pf-c-nav__link {
    cursor: pointer;
}
